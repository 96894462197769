<template>
  <div class="box" v-if="isAllFalse">
      <img
          src="https://public.yayale.top/220222230908124748.png"
          alt=""
          class="bg-title"
      />
      <div class="box-margin">
          <div class="content-box" v-if="failEdit">
              <div class="box-item">
                  <div>乘客姓名 <span style="color: red">*</span></div>
                  <div>
                      <input
                          type="text"
                          class="input-gh"
                          v-model="passengerName"
                          @input="formatRealName"
                          placeholder="请输入"
                      />
                  </div>
              </div>
              <div class="box-item">
                  <div>联系电话 <span style="color: red">*</span></div>
                  <div>
                      <input
                          type="text"
                          class="input-gh"
                          v-model="passengerMobile"
                          maxlength="11"
                          placeholder="请输入"
                      />
                  </div>
              </div>
              <div class="box-item">
                  <div>乘客人数 <span style="color: red">*</span></div>
                  <div>
                      <input
                          type="text"
                          class="input-gh"
                          v-model="passengerNum"
                          maxlength="2"
                          placeholder="请输入"
                      />
                  </div>
              </div>
              <div class="box-item">
                  <div>起点 <span style="color: red">*</span></div>
                  <div @click="selStartAddress">
                      <div
                          :style="startAddress==null? 'color:#D2C5C0' : 'color:#000'"
                          class="input-gh"
                      >{{!startAddress ? '请选择' : startAddress.name}}</div>
                  </div>
              </div>
              <div class="box-item">
                  <div>终点 <span style="color: red">*</span></div>
                  <div @click="selEndAddress">
                    <div
                          :style="endAddress==null? 'color:#D2C5C0' : 'color:#000'"
                          class="input-gh"
                      >{{endAddress==null? '请选择' : endAddress.name}}</div>
                  </div>
              </div>
              <div class="box-item" style="height: auto;padding: 10px 0;">
                  <div>途经点 <span style="color: red"></span></div>
                  <div @click="selTjAddress">
                    <div
                          :style="tjdAddress==null? 'color:#D2C5C0' : 'color:#000'"
                          class="input-gh"
                          v-if="tjdAddress==null"
                      >请选择</div>
                    <div
                          v-else
                          class="input-gh"
                      >
                        <div v-for="(item,index) in tjdAddress" :key="index">
                            <van-tag type="success">{{index+1}} : {{item.name}}</van-tag>
                        </div>  
                    </div>
                  </div>
              </div>
              <div class="box-item">
                  <div>出发时间 <span style="color: red">*</span></div>
                  <div style="cursor: pointer" @click="selQwrq">
                      <div
                          class="title"
                          :style="qwrqText !== '' ? 'color:#272929;' : ''"
                      >
                          {{
                              qwrqText == "" ? "请选择" : qwrqText
                          }}
                      </div>
                      <img
                          src="http://middle-ground.shulanhealth.com/f-fqxq-1.png"
                          alt=""
                          class="btm-icon"
                      />
                  </div>
              </div>
              <div class="box-item1">
                  <div>是否有大件行李 <span style="color: red">*</span></div>
                  <div style="margin-top:10px;">
                      <div class="user-type" @click="isJzxq(1)">
                          <img
                              :src="
                                  jzxq == 1
                                      ? 'https://middle-ground.shulanhealth.com/zzj-sel-black.png'
                                      : 'http://middle-ground.shulanhealth.com/f-fqxq-3.png'
                              "
                              alt=""
                              class="redbtn-icon"
                          />
                          <div
                              :style="
                                  jzxq == 1
                                      ? 'color:#272929;'
                                      : 'color:#9A9B9E;'
                              "
                          >
                              无
                          </div>
                      </div>
                      <div class="user-type" @click="isJzxq(2)">
                          <img
                              :src="
                                  jzxq == 2
                                      ? 'https://middle-ground.shulanhealth.com/zzj-sel-black.png'
                                      : 'http://middle-ground.shulanhealth.com/f-fqxq-3.png'
                              "
                              alt=""
                              class="redbtn-icon"
                          />
                          <div
                              :style="
                                  jzxq == 2
                                      ? 'color:#272929;'
                                      : 'color:#9A9B9E;'
                              "
                          >
                              有
                          </div>
                      </div>
                  </div>
              </div>
              <div class="box-item1">
                  <div>是否拼车 <span style="color: red">*</span></div>
                  <div style="margin-top:10px;">
                      <div class="user-type" @click="isCarpooling(1)">
                          <img
                              :src="
                                  carpooling == 1
                                      ? 'https://middle-ground.shulanhealth.com/zzj-sel-black.png'
                                      : 'http://middle-ground.shulanhealth.com/f-fqxq-3.png'
                              "
                              alt=""
                              class="redbtn-icon"
                          />
                          <div
                              :style="
                                  carpooling == 1
                                      ? 'color:#272929;'
                                      : 'color:#9A9B9E;'
                              "
                          >
                              是
                          </div>
                      </div>
                      <div class="user-type" @click="isCarpooling(2)">
                          <img
                              :src="
                                  carpooling == 2
                                      ? 'https://middle-ground.shulanhealth.com/zzj-sel-black.png'
                                      : 'http://middle-ground.shulanhealth.com/f-fqxq-3.png'
                              "
                              alt=""
                              class="redbtn-icon"
                          />
                          <div
                              :style="
                                  carpooling == 2
                                      ? 'color:#272929;'
                                      : 'color:#9A9B9E;'
                              "
                          >
                              否
                          </div>
                      </div>
                  </div>
              </div>
              <div class="box-item">
                  <div>车费 <span style="color: red">*</span></div>
                  <div>
                      <input
                      style="width:100%;"
                          type="text"
                          class="input-gh"
                          v-model="fee"
                          maxlength="11"
                          placeholder="如不填写此项，系统默认自行沟通费用"
                      />
                  </div>
              </div>
              <div class="box-item2">
                  <div style="font-size:16px;">备注 <span style="color: red"></span></div>
                  <div>
                      <textarea
                          type="text"
                          class="input-xq"
                          v-model="remarks"
                          placeholder="请输入"
                      />
                  </div>
              </div>
              <div class="box-item4">
                  <div>重要提醒：</div>
                  <div>请您认真阅读并充分理解以下规则：</div>
                  <div>①全院实行实名制就医。</div>
                  <div>
                      ②发热、发病前28天内有境外史或14天内中高风险地区旅居史、与新冠肺炎病例或无症状感染者有接触史、接触过来自疫情中高风险地区的发热和/或有新冠十大症状的患者，请先到就近医院发热门诊就诊。
                  </div>
                  <div>
                      ③所有人员来院应全程规范佩戴口罩，主动出示健康码+行程码，并配合流行病学调查及测温，非必要不陪诊。
                  </div>
              </div>
              <div
                  class="share-url"
                  @click="submitOrEditData"
              >
                  {{ hisId == "" ? "提交行程" : "修改行程" }}
              </div>
              <div class="share-url" v-if="isLoading">正在提交...</div>
          </div>
          <van-empty
              style="height: 80%"
              description="当前链接已被填写过了哟~"
              v-else
          />
          <div style="height: 15px"></div>
      </div>
      <van-calendar
            v-model="showCalendar"
            :show-confirm="false"
            :min-date="minDate"
            :max-date="maxDate"
            :title="'请选择出行日期'"
            :color="'#079bab'"
            @confirm="selectCalendar"
        />
      <van-popup v-model="showZjlxPopup" position="bottom">
          <div style="width: 100%">
              <van-picker
                  title="请选择"
                  show-toolbar
                  :columns="zjlxCom"
                  @confirm="onConfirm"
                  @cancel="onCancel"
              />
          </div>
      </van-popup>
      <van-popup v-model="showQwyyPopup" position="bottom">
          <div style="width: 100%">
              <van-picker
                  title="请选择"
                  show-toolbar
                  :columns="jzyyCom"
                  @confirm="onConfirm1"
                  @cancel="onCancel1"
              />
          </div>
      </van-popup>
      <div class="loading" v-show="isLoading">
          <van-loading text-color="#1DA9A4" color="#1DA9A4" vertical
              >发布中...</van-loading
          >
      </div>
      <van-popup v-model="showQwrqPopup" round position="bottom">
          <div style="width: 100%">
              <van-picker
                  title="请选择出发时间"
                  show-toolbar
                  :columns="columns"
                  @confirm="onsjdConfirm"
                  @cancel="onsjdCancel"
              />
          </div>
      </van-popup>
      <!-- <van-popup v-model="showMapPopup" round position="bottom" :style="{ height: '600px' }">
          <div style="width: 100%;position: relative;">
            <div class="search-box1">
            <div class="search-box-one">
              <van-icon class="search-icon" name="search" />
              <input
                class="input-sty"
                type="text"
                v-model="keyword"
                @input="inputKey"
                placeholder="输入目的地"
              />
            </div>
          </div>
          <div style="width:90%;height:400px;margin-left:5%;padding-top: 60px;overflow-y: scroll;" class="gdt">
          <div v-for="(item,index) in searchList" :key="index" class="address-item" @click="selPoint(item)">
            <div style="width:78%;display: flex;align-items: center;">
              <van-icon name="location-o" style="font-size:18px;"/>
              <div style="margin-left:5px;width:100%;">
                  <div style="font-size: 15px;font-weight:700;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis;">
                  {{item.name}}
                  </div>
                  <div style="font-size:12px;margin-top:2px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis;">
                    {{item.district}}{{item.address}}
                  </div>
              </div>
            </div>
            <div>
              路线
            </div>
          </div>
        </div>
            <div id="container" style="'height:600px;position: absolute;left: 0;bottom: 0;'"></div>
          </div>
      </van-popup> -->
      <van-dialog v-model="showDialog" title="请核对您的信息" show-cancel-button :cancel-button-text="hisId == '' ? '去修改' : '放弃修改'" confirm-button-text='提交' @confirm="confirmJzxq">
          <div class="info-box">
             奥术大师多
          </div>
      </van-dialog>
  </div>
</template>
<script>
  import { passenger } from "@/api";
let timers = null;
import AMap from "AMap"; // 引入高德地图
var driving;
import { user } from "@/api";
import Config from "@/config";
let lsDate = "";
import Bus from "@/utils/bus";
import {
  Popup,
  Picker,
  ImagePreview,
  Loading,
  Toast,
  Empty,
  Dialog,
  Tag
} from "vant";
// import { compressImage } from "@/utils/CompressImageUtils";
export default {
  components: {
      VanLoading: Loading,
      VanPopup: Popup,
      VanPicker: Picker,
      VanEmpty: Empty,
      VanTag: Tag,
  },
  data() {
      return {
          passengerName: "",
          passengerMobile: "",
          passengerNum: "",
          fee: "",
          remarks: "",
          tripDate: "",
          tripTime: "",
          carpooling: 1,
          showDialog: false,
          showCalendar: false,
          showMapPopup:true,
          qwrqText: "",
          minDate: "",
          maxDate: "",
          columns: [
              "05:00-05:30",
              "05:30-06:00",
              "06:30-07:00",
              "07:30-08:00",
              "08:30-09:00",
              "09:30-10:00",
              "10:30-11:00",
              "11:30-12:00",
              "12:30-13:00",
              "13:30-14:00",
              "14:30-15:00",
              "15:30-16:00",
              "16:30-17:00",
              "17:30-18:00",
              "18:30-19:00",
              "19:30-20:00",
              "20:30-21:00",
              "21:30-22:00"
          ],
          showQwrqPopup: false,
          status: "",
          
          isAllFalse: false,
          isSel: 2,
          isVip: true,
          hisId: "",
          isLoading: false,
          sex: 1, // 性别 1男2女
          realName: "", // 真实名称
          idCardNumber: "", // 证件号码
          mobile: "", // 联系电话
          age: "", // 年龄
          jzxq: 1, // 就诊需求
          zjlx: "", // 证件类型
          zjlxObj: {}, // 证件类型
          qwyy: "", // 期望医院
          qwyyObj: {}, // 期望医院
          zjlxCom: [],
          jzyyCom: [],
          slhCom: {},
          jyjcbgList: [],
          jyjcbgStringList: [],
          showZjlxPopup: false,
          showQwyyPopup: false,
          qywxId: "",
          failEdit: true, //是否可以填写
          isCurrentTime: false,
          keyword: "",
          searchList:[],
          startAddress: null,
          endAddress: null,
          tjdAddress: null,
          pointTypes: 1
      };
  },
  mounted() {
      Bus.$on('my_events', this.handleEvents)
      this.isAllFalse = true;
      this.isLoading = false;
      let date = new Date();
      this.minDate = new Date(date);
      this.maxDate = new Date(this.getNextDate(date, 13));
      document.title = "填写行程";
      this.isCurrentTime = this.time_range("08:00", "17:00");
      
  },
  methods: {
    handleEvents(e) {
        this.pointTypes = JSON.parse(e).type
        if(JSON.parse(e).type == 1) {
            this.startAddress = JSON.parse(e).info
        } else if(JSON.parse(e).type == 2) {
            this.endAddress = JSON.parse(e).info
        } else {
            this.tjdAddress = JSON.parse(e).info
        }
    },
    selStartAddress() {
      this.$router.push({
        name: 'yqjc',
        query: {
          type: 1,
          info: JSON.stringify({
            startAddress: this.startAddress,
            endAddress: this.endAddress,
            tjdAddress: this.tjdAddress,
          })
        }
      })
    },
    selEndAddress() {
      this.$router.push({
        name: 'yqjc',
        query: {
          type: 2,
          info: JSON.stringify({
            startAddress: this.startAddress,
            endAddress: this.endAddress,
            tjdAddress: this.tjdAddress,
          })
        }
      })
    },
    selTjAddress() {
      this.$router.push({
        name: 'tjd-sel',
        query: {
          type: 3,
          info: JSON.stringify({
            startAddress: this.startAddress,
            endAddress: this.endAddress,
            tjdAddress: this.tjdAddress,
          })
        }
      })
    },
    inputKey() {
      this.search();
    },
    selPoint(e) {
      console.log(e, 123);
      var _this = this;
      if (driving) {
        driving.clear();
      }
      AMap.plugin("AMap.Driving", function () {
        driving = new AMap.Driving({
          // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
          policy: AMap.DrivingPolicy.LEAST_DISTANCE,
          // map 指定将路线规划方案绘制到对应的AMap.Map对象上
          map: _this.map,
          // panel 指定将结构化的路线详情数据显示的对应的DOM上，传入值需是DOM的ID
          // panel: 'container'
        });

        var startLngLat = [120.246232, 29.986425];
        var endLngLat = [e.location.lng, e.location.lat];
        console.log(endLngLat);
        _this.pointInfo = e
        driving.search(startLngLat, endLngLat, function (status, result) {
          // 未出错时，result即是对应的路线规划方案
          console.log(status);
          console.log(result);
          _this.drevingInfo = result
        });
        _this.showPopup = false
      });
    },
    search() {
      var _this = this;
      clearTimeout(timers)
      timers = setTimeout(() => {
          this.autoComplete.search(this.keyword, function (status, result) {
          // 搜索成功时，result即是对应的匹配数据
          console.log(status);
          console.log(result);
          _this.searchList = result.tips;
        });
      }, 300);
    },
    initMap() {
      var _this = this;
      const dom = document.getElementById("container");
      this.map = new AMap.Map(dom, {
        resizeEnable: true,
        zoom: 15,
        center: [120.246232, 29.986425],
        showLabel: true, //不显示地图文字标记, 终于可以配置了TAT
      });
      // // JSAPI 2.0 输入提示插件名称由 AMap.Autocomplete 变更为 AMap.AutoComplete 啦！
      // AMap.plugin('AMap.ToolBar',function(){//异步加载插件
      //     var toolbar = new AMap.ToolBar();
      //     _this.map.addControl(toolbar);
      // });

      AMap.plugin("AMap.AutoComplete", function () {
        var autoOptions = {
          //city 限定城市，默认全国
          city: "全国",
        };
        // 实例化AutoComplete
        _this.autoComplete = new AMap.Autocomplete(autoOptions);
      });
    },


      formatRealName() {
          this.passengerName = this.deleteNum(this.passengerName);
      },
      deleteNum(str) {
          let reg = /[0-9]+/g;
          let str1 = str.replace(reg, "");
          return str1;
      },
      onsjdConfirm(value) {
          this.qwrqText = lsDate + " " + value;
          this.tripDate = lsDate
          this.tripTime = value
          this.showQwrqPopup = false;
      },
      onsjdCancel() {
          this.showQwrqPopup = false;
          this.showCalendar = true;
          // this.qwrqText = ""
      },
      selectCalendar(e) {
          lsDate = this.getNextDate(e, 0);
          this.showCalendar = false;
          this.showQwrqPopup = true;
      },
      selQwrq() {
          this.showCalendar = true;
      },
      getNextDate(date, day) {
          var dd = new Date(date);
          dd.setDate(dd.getDate() + day);
          var y = dd.getFullYear();
          var m =
              dd.getMonth() + 1 < 10
                  ? "0" + (dd.getMonth() + 1)
                  : dd.getMonth() + 1;
          var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
          return y + "-" + m + "-" + d;
      },
      time_range(beginTime, endTime) {
          var strb = beginTime.split(":");

          if (strb.length != 2) {
              return false;
          }

          var stre = endTime.split(":");
          if (stre.length != 2) {
              return false;
          }

          var b = new Date();
          var e = new Date();
          var n = new Date();

          b.setHours(strb[0]);
          b.setMinutes(strb[1]);
          e.setHours(stre[0]);
          e.setMinutes(stre[1]);

          if (
              n.getTime() - b.getTime() > 0 &&
              n.getTime() - e.getTime() < 0
          ) {
              return true;
          } else {
              return false;
          }
      },
      jsidCard() {
          if (
              this.idCardNumber.length == 18 ||
              this.idCardNumber.length == 15
          ) {
              let ageAndsex = this.analyzeIDCard(
                  this.idCardNumber.length,
                  this.idCardNumber
              );
              this.age = ageAndsex.age;
              this.sex = ageAndsex.sex;
          }
      },
      initData() {
          let zjlxPro = new Promise((resolve, reject) => {
              user.getMasterData("H20503")
                  .then((res) => {
                      let lsList = [];
                      for (let i in res.data.result) {
                          lsList.push({
                              text: res.data.result[i]
                                  .nationalStandardMeaning,
                              title: res.data.result[i]
                                  .nationalStandardMeaning,
                              value: res.data.result[i]
                                  .nationalStandardValues,
                          });
                      }
                      resolve(lsList);
                  })
                  .catch(() => {
                      reject();
                      this.isLoading = false;
                  });
          });

          let qwyyPro = new Promise((resolve, reject) => {
              user.getMasterData("Z90010")
                  .then((res) => {
                      let lsList = [];
                      for (let i in res.data.result) {
                          lsList.push({
                              text: res.data.result[i]
                                  .nationalStandardMeaning,
                              title: res.data.result[i]
                                  .nationalStandardMeaning,
                              value: res.data.result[i]
                                  .nationalStandardValues,
                          });
                      }
                      resolve(lsList);
                  })
                  .catch(() => {
                      reject();
                      this.isLoading = false;
                  });
          });
          Promise.all([zjlxPro, qwyyPro])
              .then((res) => {
                  this.zjlxCom = res[0];
                  this.qwyyCom = res[1];
                  //   this.qwyyCom.splice(2,1)

                  this.slhCom = res[1][2];
                  this.allQwyyCom = res[1];
                  this.getWxHisInfo();
              })
              .catch((err) => {
                  console.log(err);
              });
      },
      getWxUserInfo() {
          user.wxUserInfo().then((res) => {
              if (res.data.code == 200) {
                  if(res.data.result.name!=null) {
                      this.realName = res.data.result.name
                      this.zjlxObj = {
                              text: '居民身份证',
                              title: '居民身份证',
                              value: 1,
                      }
                      this.idCardNumber = res.data.result.certificateNumber
                      this.mobile = res.data.result.phone
                      this.jsidCard()
                  }
              }
              this.isLoading = false;
          });
      },
      getWxHisInfo() {
          let params = {
              createLinksId: this.getQueryString("id"),
          };
          user.wxHisInfo(params).then((res) => {
              if (res.data.code == 200) {
                  this.isAllFalse = true;
                  this.isVip = res.data.result.isVip;
                  if (res.data.result.isWrite == false) {
                      this.failEdit = false;
                  } else {
                      this.failEdit = true;
                      if (res.data.result.lsWriteContent !== null) {
                          this.status = res.data.result.lsWriteContent.status;
                          this.realName = res.data.result.lsWriteContent.name;
                          this.zjlxObj = this.getzjlx(
                              res.data.result.lsWriteContent.certificateType
                          );
                          this.qwyyObj = this.getqwyy(
                              res.data.result.lsWriteContent.expectedHospital
                          );
                          this.idCardNumber =
                              res.data.result.lsWriteContent.certificateNumber;
                          this.mobile = res.data.result.lsWriteContent.phone;
                          this.hisId = res.data.result.lsWriteContent.id;
                          this.age = res.data.result.lsWriteContent.age;
                          this.sex = res.data.result.lsWriteContent.sex;
                          this.qwrqText =
                              res.data.result.lsWriteContent.expectedTime;
                          this.remarks =
                              res.data.result.lsWriteContent.medicalNeeds;
                          this.jzxq = res.data.result.lsWriteContent.jzxq;
                          this.jyjcbgList =
                              res.data.result.lsWriteContent
                                  .inspectionReport == ""
                                  ? []
                                  : this.getImgList(
                                        res.data.result.lsWriteContent
                                            .inspectionReport
                                    );
                          this.jyjcbgStringList =
                              res.data.result.lsWriteContent
                                  .inspectionReport == ""
                                  ? []
                                  : this.getImgList1(
                                        res.data.result.lsWriteContent
                                            .inspectionReport
                                    );
                      } else {
                          this.status = 1;
                          // this.getWxUserInfo();
                      }
                  }
              }
              this.isLoading = false;
          });
      },
      getImgList(e) {
          let rtList = [];
          let imgs = e.split(",");
          for (let i in imgs) {
              rtList.push(Config.resource.base + imgs[i]);
          }
          return rtList;
      },
      getImgList1(e) {
          let rtList = [];
          let imgs = e.split(",");
          for (let i in imgs) {
              rtList.push(imgs[i]);
          }
          return rtList;
      },
      getzjlx(e) {
          for (let i in this.zjlxCom) {
              if (e == this.zjlxCom[i].value) {
                  return this.zjlxCom[i];
              }
          }
      },
      getqwyy(e) {
          for (let i in this.allQwyyCom) {
              if (e == this.allQwyyCom[i].value) {
                  return this.allQwyyCom[i];
              }
          }
      },
      submitOrEditData() {
        console.log(this.startAddress,'xxtx')
        this.isLoading = true
        let params = {
          passengerName: this.passengerName,
          passengerMobile: this.passengerMobile,
          passengerNum: this.passengerNum,
          startAddress: this.startAddress.name,
          endAddress: this.endAddress.name,
          startDistrict: this.startAddress.district,
          endDistrict: this.endAddress.district,
          luggage: this.jzxq,
          fee: this.fee,
          startLng: this.startAddress.location[0],
          startLat: this.startAddress.location[1],
          endLng: this.endAddress.location[0],
          endLat: this.endAddress.location[1],
          remarks: this.remarks,
          tripDate: this.tripDate,
          tripTime: this.tripTime,
          carpooling: this.carpooling,
          tjd: JSON.stringify(this.tjdAddress),

        }
        console.log(params,2221)
        passenger.addPassengerTrip(params).then(res=>{
          if(res.data.code == 200) {
            this.isLoading = false
            Dialog.alert({
                title: 'Hi',
                message: '发布成功~',
                }).then(() => {
                // on close
                });
          }
        })
          // if (this.realName == "") {
          //     Toast.fail("请填写真实姓名");
          //     return;
          // }
          // if (JSON.stringify(this.zjlxObj) == "{}") {
          //     Toast.fail("请选择证件类型");
          //     return;
          // }
          // if (this.idCardNumber == "") {
          //     Toast.fail("请填写证件号码");
          //     return;
          // }
          // if (this.mobile == "") {
          //     Toast.fail("请填写联系电话");
          //     return;
          // }
          // if (JSON.stringify(this.qwyyObj) == "{}") {
          //     Toast.fail("请选择期望医院");
          //     return;
          // }
          // if (this.qwrqText == "") {
          //     Toast.fail("请选择期望就诊日期");
          //     return;
          // }

          // this.showDialog = true
          
      },
      confirmJzxq() {
          if (this.hisId == "") {
              this.submitData();
          } else {
              this.editData();
          }
          this.showDialog = false
      },
      submitData() {
          
          this.isLoading = true;
          let params = {
              age: this.age,
              certificateNumber: this.idCardNumber,
              certificateType: this.zjlxObj.value,
              createLinksId: this.qywxId,
              expectedHospital: this.qwyyObj.value,
              inspectionReport: this.jyjcbgStringList.toString(),
              medicalNeeds: this.remarks,
              jzxq: this.jzxq,
              name: this.realName,
              phone: this.mobile,
              sex: this.sex,
              expectedTime: this.qwrqText,
          };
          user.wxSubCon(params).then((res) => {
              if (res.data.code == 200) {
                  this.hisId = res.data.result.id;
                  this.isLoading = false;
                  Dialog({
                      title: this.isCurrentTime
                          ? ""
                          : "您的就诊需求已提交成功啦~",
                      message: this.isCurrentTime
                          ? "您的就诊需求已提交成功啦~"
                          : "(小桥已经下班咯，上班后将与您联系)",
                      confirmButtonText: "知道了",
                      confirmButtonColor: "#1DA9A4",
                  });
              } else {
                  this.isLoading = false;
                  Toast.fail(res.data.message);
              }
          });
      },
      editData() {
          if (this.realName == "") {
              Toast.fail("请填写真实姓名");
              return;
          }
          if (JSON.stringify(this.zjlxObj) == "{}") {
              Toast.fail("请选择证件类型");
              return;
          }
          if (this.idCardNumber == "") {
              Toast.fail("请填写证件号码");
              return;
          }
          if (this.mobile == "") {
              Toast.fail("请填写联系电话");
              return;
          }
          if (JSON.stringify(this.qwyyObj) == "{}") {
              Toast.fail("请选择期望医院");
              return;
          }
          if (this.qwrqText == "") {
              Toast.fail("请选择期望就诊日期");
              return;
          }
          this.isLoading = true;
          let params = {
              age: this.age,
              id: this.hisId,
              certificateNumber: this.idCardNumber,
              certificateType: this.zjlxObj.value,
              createLinksId: this.qywxId,
              expectedHospital: this.qwyyObj.value,
              inspectionReport: this.jyjcbgStringList.toString(),
              medicalNeeds: this.remarks,
              jzxq: this.jzxq,
              name: this.realName,
              phone: this.mobile,
              sex: this.sex,
              expectedTime: this.qwrqText,
          };
          user.wxEditCon(params).then((res) => {
              if (res.data.code == 200) {
                  this.isLoading = false;
                  Dialog({
                      title: this.isCurrentTime
                          ? ""
                          : "您的就诊需求修改成功啦~",
                      message: this.isCurrentTime
                          ? "您的就诊需求修改成功啦~"
                          : "(小桥已经下班咯，上班后将与您联系)",
                      confirmButtonText: "知道了",
                      confirmButtonColor: "#1DA9A4",
                  });
              } else {
                  this.isLoading = false;
                  Toast.fail(res.data.message);
              }
          });
      },
      analyzeIDCard(val, idcar) {
          let iden = idcar;
          let sex = null;
          let myDate = new Date();
          let month = myDate.getMonth() + 1;
          let day = myDate.getDate();
          let age = 0;
          let sumData = {};
          if (val === 18) {
              age = myDate.getFullYear() - iden.substring(6, 10) - 1;
              sex = iden.substring(16, 17);

              if (
                  iden.substring(10, 12) < month ||
                  (iden.substring(10, 12) == month &&
                      iden.substring(12, 14) <= day)
              )
                  age++;
          }
          if (val === 15) {
              age = myDate.getFullYear() - iden.substring(6, 8) - 1901;
              sex = iden.substring(14, 15);

              if (
                  iden.substring(8, 10) < month ||
                  (iden.substring(8, 10) == month &&
                      iden.substring(10, 12) <= day)
              )
                  age++;
          }

          // if (sex % 2 === 0) sex = "0";
          // else sex = "1";
          sumData = {
              age: age,
              sex: sex,
          };
          return sumData;
      },
      // 获取下拉框数据
      getidCardByCode() {
          this.zjlxCom = [];
          user.getMasterData("H20503")
              .then((res) => {
                  for (let i in res.data.result) {
                      this.zjlxCom.push({
                          text: res.data.result[i].nationalStandardMeaning,
                          title: res.data.result[i].nationalStandardMeaning,
                          value: res.data.result[i].nationalStandardValues,
                      });
                  }
                  // this.zjlxCom = lsList
                  this.isLoading = false;
                  this.showZjlxPopup = true;
              })
              .catch(() => {
                  this.isLoading = false;
              });
      },
      // 获取下拉框数据
      getQwHospitalByCode() {
          let lsList = [];
          user.getMasterData("Z90010")
              .then((res) => {
                  for (let i in res.data.result) {
                      lsList.push({
                          text: res.data.result[i].nationalStandardMeaning,
                          title: res.data.result[i].nationalStandardMeaning,
                          value: res.data.result[i].nationalStandardValues,
                      });
                  }
                  lsList.splice(2, 1);
                  this.jzyyCom = lsList;
                  this.slhCom = lsList[2];
                  this.isLoading = false;
                  this.showQwyyPopup = true;
              })
              .catch(() => {
                  this.isLoading = false;
              });
      },
      upLoadImg(e) {
          let file = e.target.files[0];
          let fd = new FormData();
          fd.append("file", file);
          this.uploadImg(fd);
          // console.log(file);
          // this.compressImg(file);
          // let fd = new FormData()
          // fd.append('file', file)
          // this.uploadFile(fd, s)
      },
      uploadImg(fd) {
          // let params = {
          //     file: fd,
          //     biz: 'temp'
          // };
          this.isLoading = true;
          user.upLoadFile(fd)
              .then((res) => {
                  this.jyjcbgList.push(
                      Config.resource.base + res.data.message
                  );
                  this.jyjcbgStringList.push(res.data.message);
                  this.isLoading = false;
              })
              .catch(() => {
                  this.isLoading = false;
              });
      },
      getQueryString: function (name) {
          let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
          let r = window.location.search.substr(1).match(reg);
          if (r != null) {
              return unescape(decodeURI(r[2]));
          }
      },
      delPic(index) {
          this.jyjcbgList.splice(index, 1);
          this.jyjcbgStringList.splice(index, 1);
      },
      showPic(index) {
          ImagePreview(this.jyjcbgList, index);
      },
      gzxqdClick(e) {
          this.$router.push({ name: "gzxqd", query: e });
      },
      isUserSex(e) {
          this.sex = e;
      },
      isJzxq(e) {
          this.jzxq = e;
      },
      isCarpooling(e) {
          this.carpooling = e;
      },
      
      parentData(e) {
          this.isSel = e;
      },
      selZjlx() {
          this.isLoading = true;
          this.getidCardByCode();
      },
      selQwyy() {
          this.isLoading = true;
          this.getQwHospitalByCode();
      },
      onConfirm(value) {
          this.zjlx = value.text;
          this.zjlxObj = value;
          this.showZjlxPopup = false;
      },
      onCancel() {
          this.showZjlxPopup = false;
      },
      onConfirm1(value) {
          this.qwyy = value.text;
          this.qwyyObj = value;
          this.showQwyyPopup = false;
      },
      onCancel1() {
          this.showQwyyPopup = false;
      },
  },
};
</script>
<style scoped>
/deep/ .van-calendar__confirm {
  height: 45px !important;
  font-size: 16px;
}
.search-box1 {
  position: absolute;
  left: 0;
  top:0;
  width: 100%;
  display: flex;
  background: #fff;
  height: 60px;
  align-items: center;
  z-index: 99999999999;
}
.search-box {
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 100%;
  display: flex;
  background: #fff;
  min-height: 60px;
  flex-direction: column;
  /* align-items: center; */
  z-index: 99999999999;
}
.search-box-one {
  position: relative;
  width: 90%;
  margin-left: 5%;
  height: 40px;
  border: 2px solid #f2f2f2;
  border-radius: 40px;
  display: flex;
  align-items: center;
}
.input-sty {
  width: 80%;
  margin-left: 2%;
  height: 35px;
  line-height: 35px;
  border: none;
}
.search-icon {
  font-size: 20px;
  margin-left: 10px;
}
.info-box {
  width: 92%;
  margin-left: 4%;
  padding: 5px 0 10px 0;
}
input {
  padding: 0;
}
textarea {
  padding: 0;
}
.box-margin {
  width: 94%;
  margin-left: 3%;
  position: relative;
  top: -30px;
}
.bg-title {
  width: 100%;
}
.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.x-box1 .img-list:nth-child(4) {
  margin-right: 0;
}

.x-box1 .img-list:nth-child(8) {
  margin-right: 0;
}
.img-list {
  position: relative;
  width: 23%;
  height: 77px;
  margin-bottom: 8px;
  margin-right: 2%;
}
.dele {
  width: 22px;
  height: 22px;
  position: absolute;
  top: -7px;
  right: -7px;
  background: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
}
.add-img1 {
  position: relative;
  width: 23%;
  display: flex;
  align-items: center;
  height: 77px;
  margin-bottom: 7px;
}

.file {
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #1e88c7;
  text-decoration: none;
  text-indent: 0;
  line-height: 20px;
  width: 100%;
  height: 87px;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file input {
  width: 100%;
  height: 87px;
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
}

.file:hover {
  color: #004974;
  text-decoration: none;
}

.img-size1 {
  width: 20px;
  height: 20px;
}
.box {
  height: 100%;
  position: relative;
  background: #eef7f8;
}
.bar {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.box-item:last-child {
  border-bottom: none;
}
.share-url {
  width: 92%;
  margin-left: 4%;
  height: 49px;
  background: #079bab;
  line-height: 49px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;
}
.content-box {
  width: 100%;
  /* margin-top: 9px; */
  background: #fff;
  padding-bottom: 15px;
  border-radius: 10px;
}
.box-item {
  height: 77px;
  width: 92%;
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #f2f2f2;
}
.box-item2 {
  height: 121px;
  width: 92%;
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #f2f2f2;
}
.box-item3 {
  /* height: 121px; */
  width: 92%;
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border-bottom: 2px solid #f2f2f2; */
}
.box-item4 {
  /* height: 121px; */
  width: 92%;
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border-bottom: 2px solid #f2f2f2; */
  background: rgba(236, 165, 48, 0.16);
  padding: 10px;
  border-radius: 5px;
  color: #eca530;
}
.box-item6 {
  /* height: 121px; */
  position: absolute;
  left: 0;
  top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border-bottom: 2px solid #f2f2f2; */
  background: rgba(236, 165, 48, 0.76);
  padding: 5px 10px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  color: #fff;
}
.box-item3 > div:nth-child(1) {
  font-size: 14px;
  color: #4b4d4d;
  margin-top: 15px;
  margin-bottom: 10px;
}
.box-item3 > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.box-item2 > div:nth-child(1) {
  font-size: 14px;
  color: #4b4d4d;
}
.box-item2 > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.box-item > div:nth-child(1) {
  font-size: 16px;
  color: #4b4d4d;
}
.box-item > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.box-item1 {
  height: 77px;
  width: 92%;
  margin-left: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #f2f2f2;
}
.box-item1 > div:nth-child(1) {
  font-size: 16px;
  color: #4b4d4d;
}
.box-item1 > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 8px;
}
.btm-icon {
  width: 12px;
}
.title {
  font-size: 15px;
  color: #c0c0c0;
  margin-top: 8px;
}
.user-type {
  width: 30%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
}
.redbtn-icon {
  width: 18px;
  margin-right: 8px;
}
.input-gh {
  width: 80%;
  border: none;
  margin-top: 8px;
  font-size: 15px;
}
.input-xq {
  width: 100%;
  border: none;
  margin-top: 8px;
  height: 66px;
  font-size: 15px;
  resize: none;
}
input::placeholder {
  color: #c0c0c0;
}
textarea::placeholder {
  color: #c0c0c0;
}
.list-item {
  margin-bottom: 10px;
}
.leftbtn {
  display: flex;
  align-items: center;
}
.gzxqd {
  height: 26.5px;
  border: 1px solid #079bab;
  color: #079bab;
  line-height: 26.5px;
  font-size: 12px;
  text-align: center;
  padding: 0 10px;
  border-radius: 26px;
}
</style>
